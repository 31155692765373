import React from 'react';
import styled from 'styled-components';

import MainNavigationBarComponent from './navigation-bar/MainNavigationBarComponent';
import SecondaryNavigationBarComponent from './navigation-bar/SecondaryNavigationBarComponent';

const NavBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function NavigationBarComponent({ headerstyle }) {
  return headerstyle !== 'none' ? (
    <NavBarWrapper>
      <MainNavigationBarComponent />
      <SecondaryNavigationBarComponent />
    </NavBarWrapper>
  ) : null;
}

export default NavigationBarComponent;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Drawer, Collapse } from 'antd';
import styled from 'styled-components';

import ExpandedOurExpertiseComponent from './main-navigation-bar/ExpandedOurExpertiseComponent';
import ExpandedIndustryInsightsComponent from './main-navigation-bar/ExpandedIndustryInsightsComponent';
import ExpandedAboutUsComponent from './secondary-navigation-bar/ExpandedAboutUsComponent';

const { Panel } = Collapse;

const links = [
  { text: 'FIND A PROPERTY', link: '/find-a-property', comingSoon: true },
  { text: 'CAREERS', link: '/careers' },
  { text: 'EVENTS', link: '/event-schedules' },
  { text: 'PRESS ROOM', link: '/press-room' },
  { text: 'CONTACT US', link: '/contact-us' },
];

const StyledNavigationDrawer = styled(Drawer)`
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-body {
        padding: 24px 0px;
      }
    }
  }
  .ant-collapse-header {
    font-weight: 600;
    /* letter-spacing: 1px; */
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      background-color: #fafafa;
    }
  }

  .styled-content {
    margin-top: 64px;
    display: flex;
    flex-direction: column;

    .link {
      padding: 12px 16px;
      font-weight: 600;
      /* letter-spacing: 1px; */

      a {
        color: inherit;
      }
    }
  }
  div.ant-ribbon.ant-ribbon-placement-end {
    top: 0px;
    font-size: 10px;
    line-height: 12px;
    height: auto;
    padding: 1px 4px;
    left: 90px;
    right: unset;
    font-weight: 600;
    letter-spacing: 1px;
  }
`;

const Content = () => (
  <div className="styled-content">
    <Collapse defaultActiveKey={3} expandIconPosition="right" accordion ghost>
      <Panel header="OUR EXPERTISE" key="1">
        <ExpandedOurExpertiseComponent />
      </Panel>
      <Panel header="INDUSTRY INSIGHTS" key="2">
        <ExpandedIndustryInsightsComponent />
      </Panel>
      <Panel header="ABOUT US" key="3">
        <ExpandedAboutUsComponent />
      </Panel>
    </Collapse>
    {links.map(value => (
      <div className="link" key={value.text}>
        <Link to={value.link}>{value.text}</Link>
      </div>
    ))}
  </div>
);

function NavigationDrawerComponent({ drawerVisibility, handleOnClick }) {
  return (
    <StyledNavigationDrawer
      placement="right"
      closable={false}
      onClose={() => handleOnClick()}
      visible={drawerVisibility}
      zIndex={9}
      width="100%"
      // getContainer="main"
    >
      <Content />
    </StyledNavigationDrawer>
  );
}

NavigationDrawerComponent.propTypes = {
  drawerVisibility: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default NavigationDrawerComponent;

import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const pages = [
  {
    title: 'Our Expertise',
    description:
      'Whatever your real estate service needs may be, our expert team of dedicated professionals will deliver value beyond your expectations.',
    linksLayout: 'flex-row',
    links: [
      { text: 'Starter', link: '/our-expertise/starter' },
      { text: 'Experienced', link: '/our-expertise/experienced' },
      { text: 'Seasoned', link: '/our-expertise/seasoned' },
    ],
  },
  {
    title: 'Find a Property',
    description:
      'Browse our offered available properties. We have lots or spaces that suit your requirements.',
    linksLayout: 'flex-column',
    links: [
      {
        externalLink: true,
        text: 'Office Spaces for Sale and Lease',
        link: 'https://offices.primephilippines.com/',
      },
      {
        externalLink: true,
        text: 'Commercial Spaces and Lots for Sale and Lease',
        link: 'https://commercialproperties.primephilippines.com/',
      },
      {
        externalLink: true,
        text: 'Industrial Warehouses and Lots for Lease',
        link: 'https://propwise.primephilippines.com/',
      },
    ],
  },
  {
    title: 'About Us',
    description: '',
    linksLayout: 'flex-column',
    links: [
      { text: 'PRIME Advantage', link: '/prime-advantage' },
      { text: 'PRIME Leadership', link: '/leadership' },
      {
        text: 'Corporate Sustainability',
        link: '/city-enabling',
      },
      { text: 'Awards & Recognition', link: '/awards-and-recognition' },
      { text: 'Careers at PRIME', link: '/careers' },
      { text: 'Press Room', link: '/press-room' },
    ],
  },
  {
    title: 'Industry Insights',
    description:
      'Secure your competitive edge with our insights spanning various industries and competencies.',
    linksLayout: 'flex-row',
    links: [{ text: 'COVID-19 Coverage', link: '/covid-19' }],
    linksGroupTitle: 'By sector:',
    linksGroup: [
      { text: 'Industrial', link: '/insights/industrial' },
      { text: 'Office', link: '/insights/offices' },
      { text: 'Retail', link: '/insights/retail' },
    ],
  },
  {
    title: 'Book a Consultation',
    description: 'Unsure about what you need? We can help.',
    linksLayout: 'flex-row',
    links: [{ text: 'Book a Consultation', link: '/contact-us' }],
  },
  {
    title: 'Social Media',
    description: '',
    linksLayout: 'styled-icon',
    links: [
      {
        icon: ['fab', 'facebook-square'],
        link: 'https://www.facebook.com/PRIMEPhilippines/',
      },
      {
        icon: ['fab', 'linkedin'],
        link: 'https://www.linkedin.com/company/prime-corporation-philippines',
      },
      {
        icon: ['fab', 'youtube'],
        link: 'https://www.youtube.com/channel/UCSKs5Adg8HUIkCpU789VgRw',
      },
    ],
  },
];

const contactDetails = {
  title: 'Talk to Us',
  contactNo: ['(+632) 8442 8888', '(+632) 7616 8554', '(+63) 917 555 8222'],
  emails: [
    {
      type: 'For general inquiries:',
      emailAddress: 'inquiries@primephilippines.com',
    },
    {
      type: 'For client concerns:',
      emailAddress: 'customersupport@primephilippines.com',
    },
    {
      type: 'Recruitment and hiring concerns:',
      emailAddress: 'recruitment@primephilippines.com',
    },
    {
      type: 'Press and public communications:',
      emailAddress: 'pressroom@primephilippines.com',
    },
  ],
};

const offices = [
  {
    city: 'MANILA',
    address:
      'Ground Floor, Pacific Century Tower #1472 - 1476, Quezon Avenue South Triangle, Quezon City, Philippines',
  },
  {
    city: 'DAVAO',
    address:
      'Fourth Floor, Topaz Tower, Damosa I.T. Park, J.P. Laurel Avenue, Lanang, Davao City, Davao Del Sur, Philippines 8000',
  },
  {
    city: 'CEBU',
    address:
      'Regus - Cebu, Park Centrale, 5th Floor, Park Centrale Building Jose Maria del Mar St, cor Abad St, Cebu City 6000',
  },
];

const StyledMainFooter = styled.div`
  width: 100%;
  max-width: 1440px;
  background-color: ${props => props.theme.blackPalette.original};
  padding: 8px 0px;

  .pages-wrapper,
  .contacts-wrapper {
    margin: 16px 12px;

    h4 {
      color: ${props => props.theme.primeYellowPalette.original};
      margin-bottom: 4px;
    }

    p {
      color: ${props => props.theme.blackPalette.light};
      margin-bottom: 4px;
    }

    a {
      color: ${props => props.theme.whitePalette.original};
      text-decoration: underline;
    }

    .flex-row {
      display: flex;

      a {
        margin-right: 16px;
      }
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      line-height: 28px;
    }

    .styled-icon {
      display: flex;

      a {
        margin-right: 16px;
        font-size: 24px;
      }
    }
  }

  .address-wrapper {
    margin: 16px 12px;
    color: ${props => props.theme.whitePalette.original};

    h4 {
      margin-bottom: 8px;
    }
  }
`;

const MainFooterComponent = () => (
  <StyledMainFooter>
    <Row type="flex" justify="center">
      <Col md={22} sm={23} xs={23}>
        <Row type="flex">
          <Col xxl={14} sm={24} xs={24}>
            <Row type="flex" justify="space-between">
              {pages.map(data => (
                <Col xxl={8} lg={8} md={12} sm={24} sx={24} key={data.title}>
                  <div className="pages-wrapper">
                    <h4>{data.title}</h4>
                    <p>{data.description}</p>
                    <div className={data.linksLayout}>
                      {data.links.map(value => (
                        <div key={value.text || value.icon}>
                          {value.icon ? (
                            <OutboundLink href={value.link}>
                              <FontAwesomeIcon icon={value.icon} />
                            </OutboundLink>
                          ) : value.externalLink ? (
                            <OutboundLink href={value.link}>
                              {value.text}
                            </OutboundLink>
                          ) : (
                            <Link to={value.link}>{value.text}</Link>
                          )}
                        </div>
                      ))}
                    </div>
                    {data.linksGroup && (
                      <div>
                        <p>{data.linksGroupTitle}</p>
                        <div className={data.linksLayout}>
                          {data.linksGroup.map(group => (
                            <Link to={group.link} key={group.text}>
                              {group.text}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xxl={10} sm={24} xs={24}>
            <Row type="flex">
              <Col xxl={12} lg={8} md={12} sm={24} xs={24}>
                <div className="contacts-wrapper">
                  <h4>{contactDetails.title}</h4>
                  {contactDetails.contactNo.map(number => (
                    <div key={number}>
                      <a href={`tel:${number}`}>: {number}</a>
                    </div>
                  ))}
                  {contactDetails.emails.map(email => (
                    <div key={email.emailAddress}>
                      <p>{email.type}</p>
                      <a href={`mailto:${email.emailAddress}`}>
                        {email.emailAddress}
                      </a>
                    </div>
                  ))}
                </div>
              </Col>
              <Col xxl={12} lg={16} md={12} sm={24} xs={24}>
                <Row type="flex">
                  {offices.map(location => (
                    <Col xxl={24} lg={12} sm={24} xs={24} key={location.city}>
                      <div className="address-wrapper">
                        <h4>{location.city}</h4>
                        <p>{location.address}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledMainFooter>
);

export default MainFooterComponent;

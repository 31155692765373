import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Popover } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExpandedAboutUsComponent from './secondary-navigation-bar/ExpandedAboutUsComponent';

const secondaryLinks = [
  { text: 'CAREERS', link: '/careers' },
  { text: 'EVENTS', link: '/event-schedules' },
  { text: 'PRESS ROOM', link: '/press-room' },
  { text: 'CONTACT US', link: '/contact-us' },
];

const StyledSecondaryNavBar = styled.div`
  max-width: 1440px;
  width: 100%;
  position: fixed;
  z-index: 9;
  top: 64px;
  background-color: ${props => props.theme.whitePalette.original};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);

  .covid-banner {
    width: 90%;
    float: left;
    padding-left: 18px;
    padding-right: 36px;
    background-color: ${props => props.theme.primeBluePalette.original};

    a {
      color: ${props => props.theme.whitePalette.original};
      font-weight: 600;
      text-decoration: underline;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .shape-wrap {
    float: left;
    margin-left: -2px;
    filter: drop-shadow(8px -1px 6px rgba(0, 0, 0, 0.16));

    .shape {
      clip-path: polygon(0 0, 100% 0%, 20% 100%, 0% 100%);
      background-color: ${props => props.theme.primeBluePalette.original};
      height: 25px;
      width: 24px;
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .link-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-right: 22px;

    .link {
      cursor: pointer;
      font-weight: 600;
      color: ${props => props.theme.blackPalette.light};
      font-size: 14px;
      padding: 0px 12px;
    }
  }
`;

const SecondaryNavigationBarComponent = () => (
  <StyledSecondaryNavBar>
    <Row type="flex">
      <Col lg={10} md={14} sm={24} xs={24}>
        <div className="covid-banner">
          <Link to="/our-covid-19-response">See our COVID-19 response</Link>
        </div>
        <div className="shape-wrap">
          <div className="shape" />
        </div>
      </Col>
      <Col lg={14} sm={0} xs={0}>
        <div className="link-wrapper">
          <Popover
            trigger="click"
            placement="bottom"
            getPopupContainer={trigger => trigger.parentElement}
            content={<ExpandedAboutUsComponent />}
          >
            <div className="link">
              ABOUT US <FontAwesomeIcon icon="angle-down" fixedWidth />
            </div>
          </Popover>
          {secondaryLinks.map(data => (
            <Link className="link" to={data.link} key={data.text}>
              {data.text}
            </Link>
          ))}
        </div>
      </Col>
    </Row>
  </StyledSecondaryNavBar>
);

export default SecondaryNavigationBarComponent;

import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const links = [
  { text: 'Latest Insights', link: '/insights' },
  { text: 'COVID-19', link: '/insights/covid-19' },
  { text: 'Workplaces and Offices', link: '/insights/offices' },
  { text: 'Retail and Commerce', link: '/insights/retail' },
  { text: 'Industrial', link: '/insights/industrial' },
  { text: 'Hotel and Tourism', link: '/insights/hospitality' },
  { text: 'Tech and Innovation', link: '/insights/tech-and-innovation' },
  { text: 'Investments', link: '/insights/investments' },
  { text: 'Sustainability', link: '/insights/sustainability' },
];

const StyledExpandedIndustryInsights = styled(Row)`
  width: 650px;
  padding: 4px 0px;

  .left-menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    a {
      font-size: 16px;
      line-height: 28px;
      text-decoration: underline;
      color: ${props => props.theme.blackPalette.dark};
    }
  }

  .wrapper {
    border-left: ${props => `1px solid ${props.theme.whitePalette.dark}`};
    padding-left: 16px;

    .styled-card {
      padding: 16px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px;
      background-color: ${props => props.theme.whitePalette.original};

      h4 {
        margin-bottom: 4px;
        font-weight: 400;
      }

      a {
        color: ${props => props.theme.primeYellowPalette.original};
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 600;
      }

      .subtitle {
        color: ${props => props.theme.whitePalette.dark};
      }

      :last-child {
        background-color: ${props => props.theme.primeBluePalette.original};
        margin-bottom: 16px;

        .title {
          color: ${props => props.theme.primeYellowPalette.original};
        }

        .description {
          line-height: 18px;
          color: ${props => props.theme.whitePalette.original};
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      :first-child {
        .title {
          color: ${props => props.theme.primeBluePalette.original};
        }

        .description {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    width: auto;

    .wrapper {
      border-left: none;
      padding-left: 0;
    }
  }
`;

function ExpandedIndustryInsightsComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiRealEstateInsight(
        limit: 1
        sort: { order: DESC, fields: published_date }
      ) {
        nodes {
          author
          description
          strapiId
          url_link
          section
          title
          tags
          published_date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  `);

  return (
    <StyledExpandedIndustryInsights type="flex" justify="center">
      <Col lg={8} sm={23} xs={23} className="left-menu">
        <h2>Industry Insights</h2>
        {links.map(linkItem => (
          <Link
            className="insight-links"
            to={linkItem.link}
            key={linkItem.text}
          >
            {linkItem.text}
          </Link>
        ))}
      </Col>
      <Col lg={16} sm={23} xs={23}>
        <div className="wrapper">
          {data.allStrapiRealEstateInsight.nodes.map(value => (
            <div className="styled-card" key={value.title}>
              <Link to={`/insights/articles/${value.url_link}`}>
                <h4 className="title">{value.title}</h4>
              </Link>
              <div className="subtitle">
                {value.published_date} - {value.section}
              </div>
              <div className="description">{value.description}</div>
              <Link to={`/insights/articles/${value.url_link}`}>
                READ MORE <FontAwesomeIcon icon="angle-right" fixedWidth />
              </Link>
            </div>
          ))}
          <div className="styled-card">
            <a href="https://realestateintelligence.primephilippines.com/">
              <h4 className="title">
                The Road to Recovery: Real Estate Sentiments & Strategy
                Prospectus
              </h4>
            </a>
            <div className="subtitle">
              Real Estate Business Intelligence Compendium Vol II
            </div>
            <div className="description">
              Analyzing collective insights from real estate practitioners from
              various sectors – office, retail, residential, hotel, and
              industrial, this report focuses on the strategies stakeholders may
              apply to recuperate from the adverse effects of the COVID-19
              outbreak and how to move forward to sustain long-term business
              continuity.
            </div>
            <a href="https://realestateintelligence.primephilippines.com/">
              READ MORE <FontAwesomeIcon icon="angle-right" fixedWidth />
            </a>
          </div>
        </div>
      </Col>
    </StyledExpandedIndustryInsights>
  );
}

export default ExpandedIndustryInsightsComponent;

import React, { useState } from 'react';
import { Row, Col, Input, Button, notification } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import primeRadarLogo from '../../../static/images/home/prime-radar-logo.png';
import primeRadarCover from '../../../static/images/footer/radar-cover-image.png';

const StyledRadarFooter = styled.div`
  width: 100%;
  max-width: 1440px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)),
    url(${primeRadarCover});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 24px 0px;
  color: ${props => props.theme.whitePalette.original};

  img {
    margin: 0;
  }

  p {
    margin-left: 8px;
    margin-right: 8px;
  }

  .styled-form {
    display: flex;

    .ant-input,
    .ant-btn {
      margin: 0px 8px;
      height: 36px;
    }

    .ant-btn {
      background-color: ${props => props.theme.bluePalette.original};
      border-color: ${props => props.theme.bluePalette.original};
      padding: 8px 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    p {
      text-align: center;
    }

    img {
      margin-bottom: 16px;
    }
  }
`;

const isEmail = (email = null) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

const RadarFooterComponent = (props) => {
  const [clientEmail, setClientEmail] = useState('');

  const handleSubscription = e => {
    if (clientEmail !== '' && isEmail(clientEmail)) {
      window.open(
        `https://us3.list-manage.com/subscribe?u=9b0b0c2f4ba8ccf6306ab554f&id=dc6ec6b6e7&FULLNAME=&EMAIL=${clientEmail}&SOURCE=${props.source}`,
        '_blank'
      );
    } else {
      notification.error({
        message: 'Something went wrong',
        description: 'Please provide a valid Email Address',
      });
      e.preventDefault();
    }
  };
  return (
    <StyledRadarFooter>
      <Row type="flex" justify="center">
        <Col xxl={18} md={20} sm={23} xs={23}>
          <Row type="flex" justify="space-around" align="middle">
            <Col lg={8} md={10} sm={12} xs={12}>
              <img src={primeRadarLogo} alt="PRIME Radar logo" />
            </Col>
            <Col lg={12} md={14} sm={22} xs={24}>
              <div>
                <p>
                  Get the latest real estate insights, events and news from our
                  newsletter
                </p>
                <div className="styled-form">
                  <Input
                    placeholder="Enter your email address"
                    onChange={e => {
                      setClientEmail(e.target.value);
                    }}
                  />
                  <Button type="primary" onClick={handleSubscription}>
                    SUBSCRIBE
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledRadarFooter>
  );
};

RadarFooterComponent.propTypes = {
  source: PropTypes.string,
};

export default RadarFooterComponent;

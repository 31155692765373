import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cardImage from '../../../../static/images/our-expertise/hero-image.png';

const expertise = [
  {
    title: <h2>EXPERIENCED</h2>,
    description:
      'For those who are experienced in real estate - get up-to-speed with our various service offerings catering to every need.',
    link: '/our-expertise/experienced',
  },
  {
    title: <h4>STARTER</h4>,
    description:
      'For those who are new to the industry - let us help you hit the ground running.',
    link: '/our-expertise/starter',
  },
  {
    title: <h4>SEASONED</h4>,
    description:
      'Wherever you are in the industry, we can help you with find your breakthrough with our tailored-fit solutions.',
    link: '/our-expertise/seasoned',
  },
];

const StyledExpandedOurExpertise = styled(Row)`
  width: 780px;
  padding: 4px 0px;

  .menu-title {
    margin-bottom: 0px;
  }
  .menu-subtitle {
    font-size: 14px;
    line-height: 16px;
  }

  .featured-services {
    font-weight: 600;
  }

  .our-expertise {
    margin-top: 16px;
  }
  .our-expertise-link {
    font-weight: 600;
    text-decoration: underline;
  }

  .our-services {
    margin-top: 16px;
    margin-left: 16px;
    padding-left: 16px;
    border-left: ${props => `1px solid ${props.theme.whitePalette.dark}`};

    h3 {
      margin-bottom: 8px;
    }
    .other-services {
      margin-bottom: 16px;
    }

    a {
      font-size: 16px;
      line-height: 28px;
      text-decoration: underline;
      color: ${props => props.theme.blackPalette.dark};
    }
  }

  @media only screen and (max-width: 991px) {
    width: auto;
  }
`;

const StyledCard = styled.div`
  padding: 8px 12px;
  color: ${props => props.theme.whitePalette.original};
  background-size: cover;
  background-repeat: no-repeat;

  h2,
  h4,
  a {
    color: ${props => props.theme.primeYellowPalette.original};
  }

  h2,
  h4 {
    margin-bottom: 4px !important;
  }

  .description {
    line-height: 18px;
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  :nth-child(1) {
    background-position: top;
    background-image: linear-gradient(
        rgba(0, 51, 102, 0.7),
        rgba(0, 51, 102, 0.7)
      ),
      url(${cardImage});

    .description {
      margin-bottom: 16px;
    }
  }

  :nth-child(2) {
    background-position: center;
    background-image: linear-gradient(
        rgba(0, 51, 102, 0.4),
        rgba(0, 51, 102, 0.4)
      ),
      url(${cardImage});
  }

  :nth-child(3) {
    background-position: bottom;
    background-image: linear-gradient(
        rgba(0, 51, 102, 0.4),
        rgba(0, 51, 102, 0.4)
      ),
      url(${cardImage});
  }

  :nth-child(2),
  :nth-child(3) {
    .description,
    a {
      font-size: 14px;
      line-height: 16px;
    }
  }

  :nth-last-child(n + 2) {
    margin-bottom: 12px;
  }
`;

function ExpandedOurExpertiseComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiServices(
        filter: { visible: { eq: true }, service_type: { eq: "Experienced" } }
      ) {
        nodes {
          strapiId
          category
          description
          service_client_details
          service_name
          service_type
          strapiId
          tags
          url_slug
          visible
        }
      }
    }
  `);

  const featuredServices = data.allStrapiServices.nodes.filter(
    service =>
      service.service_name === 'Business Resumption' ||
      service.service_name === 'End-to-End Project Development'
  );

  const otherServices = data.allStrapiServices.nodes.filter(
    service =>
      service.service_name !== 'Business Resumption' &&
      service.service_name !== 'End-to-End Project Development'
  );

  return (
    <StyledExpandedOurExpertise type="flex" justify="center">
      <Col lg={24} sm={23} xs={23}>
        <Row type="flex" align="middle" className="styled-header">
          <Col lg={8} sm={24} xs={24}>
            <h2 className="menu-title">Our Expertise</h2>
          </Col>
          <Col lg={16} sm={24} xs={24}>
            <div className="menu-subtitle">
              Whatever your real estate service needs may be, our expert team of
              dedicated professionals will deliver value beyond your
              expectations.
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg={12} sm={23} xs={23}>
        <div className="our-expertise">
          {expertise.map(value => (
            <StyledCard key={value.link}>
              {value.title}
              <div className="description">{value.description}</div>
              <Link className="our-expertise-link" to={value.link}>
                CLICK HERE <FontAwesomeIcon icon="angle-right" fixedWidth />
              </Link>
            </StyledCard>
          ))}
        </div>
      </Col>
      <Col lg={12} sm={23} xs={23}>
        <div className="our-services">
          {/* <h3>Our Services</h3> */}

          <Row type="flex" className="other-services">
            {otherServices.map(item => (
              <Col lg={24} sm={24} xs={24} key={item.strapiId}>
                <Link
                  to={`/our-expertise/${item.service_type.toLowerCase()}/${
                    item.url_slug
                  }`}
                >
                  {item.service_name}
                </Link>
              </Col>
            ))}
          </Row>
          <div className="featured-services">
            {featuredServices.map(item => (
              <div key={item.strapiId}>
                <Link
                  to={`/our-expertise/${item.service_type.toLowerCase()}/${
                    item.url_slug
                  }`}
                >
                  {item.service_name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Col>
    </StyledExpandedOurExpertise>
  );
}

export default ExpandedOurExpertiseComponent;

import React from 'react';
import { Link } from 'gatsby';
import { Row, Col } from 'antd';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

const StyledSecondaryFooter = styled.div`
  width: 100%;
  max-width: 1440px;
  background-color: ${props => props.theme.blackPalette.dark};

  .styled-row {
    margin: 10px 12px;

    h6 {
      color: ${props => props.theme.whitePalette.original};
      margin-bottom: 0;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;

      a {
        color: ${props => props.theme.whitePalette.original};
        text-decoration: underline;
        margin-left: 16px;
      }
    }

    @media only screen and (max-width: 767px) {
      h6 {
        text-align: center;
        line-height: 24px;
      }

      .link-wrapper {
        justify-content: center;

        a {
          margin: 0px 16px;
        }
      }
    }
  }
`;

const SecondaryFooterComponent = () => (
  <StyledSecondaryFooter>
    <Row type="flex" justify="center">
      <Col md={22} sm={23} xs={23}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="styled-row"
        >
          <Col lg={12} md={12} sm={24} xs={24}>
            <h6>© 2013 - 2020 PRIME Philippines. All Rights Reserved.</h6>
          </Col>
          <Col lg={8} md={12} sm={24} xs={24} className="link-wrapper">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledSecondaryFooter>
);
SecondaryFooterComponent.propTypes = {};

export default SecondaryFooterComponent;

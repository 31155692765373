import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const aboutUsLinks = [
  { text: 'PRIME Advantage', link: '/prime-advantage' },
  { text: 'Leadership', link: '/leadership' },
  { text: 'Corporate Sustainability', link: '/city-enabling' },
  { text: 'Awards & Recognition', link: '/awards-and-recognition' },
];

const StyledExpandedAboutUs = styled(Row)`
  width: 160px;

  .link-wrapper {
    display: flex;
    flex-direction: column;
  }

  a {
    width: 100%;
    font-size: 18px;
    margin-bottom: 14px;
    line-height: 20px;
    color: ${props => props.theme.blackPalette.dark};

    :last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 991px) {
    width: auto;
  }
`;

function ExpandedAboutUsComponent() {
  return (
    <StyledExpandedAboutUs type="flex" justify="center">
      <Col lg={24} sm={23} xs={23}>
        <div className="link-wrapper">
          {aboutUsLinks.map(value => (
            <Link to={value.link} key={value.text}>
              {value.text}
            </Link>
          ))}
        </div>
      </Col>
    </StyledExpandedAboutUs>
  );
}

export default ExpandedAboutUsComponent;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledHamburgerMenu = styled.div`
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  margin: auto;
  width: ${props => `${props.barWidth}px`};
  height: ${props => `${props.barHeight + props.barSpacing * 2}px`};
  cursor: pointer;
  z-index: 20;

  .hamburger-menu,
  .hamburger-menu:after,
  .hamburger-menu:before {
    width: ${props => `${props.barWidth}px`};
    height: ${props => `${props.barHeight}px`};
  }

  .hamburger-menu {
    position: relative;
    transform: ${props => `translateY(${props.barSpacing}px)`};
    background: ${props => props.theme.primeBluePalette.original};
    transition: all 0ms 300ms;

    &.animate {
      background: rgba(255, 255, 255, 0);
    }
  }

  .hamburger-menu:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: ${props => `${props.barSpacing}px`};
    background: ${props => props.theme.primeBluePalette.original};
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .hamburger-menu:after {
    content: '';
    position: absolute;
    left: 0;
    top: ${props => `${props.barSpacing}px`};
    background: ${props => props.theme.primeBluePalette.original};
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
`;

const HamburgerMenuComponent = ({ drawerVisibility, handleOnClick }) => (
  <StyledHamburgerMenu
    barWidth={32}
    barHeight={4}
    barSpacing={8}
    onClick={() => handleOnClick()}
  >
    <div className={`hamburger-menu ${drawerVisibility && 'animate'}`} />
  </StyledHamburgerMenu>
);

HamburgerMenuComponent.propTypes = {
  drawerVisibility: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

HamburgerMenuComponent.defaultProps = {
  drawerVisibility: false,
};

export default HamburgerMenuComponent;

import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Row, Col, Popover } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HamburgerMenuComponent from './HamburgerMenuComponent';
import NavigationDrawerComponent from './NavigationDrawerComponent';
import ExpandedOurExpertiseComponent from './main-navigation-bar/ExpandedOurExpertiseComponent';
import ExpandedIndustryInsightsComponent from './main-navigation-bar/ExpandedIndustryInsightsComponent';

import logo from '../../../static/logo.png';

const StyledLogo = styled.div`
  img {
    cursor: pointer;
    padding: 10px 0px 10px 16px;
    height: 64px;
    background-color: white;
    float: left;
    margin-bottom: 0;
  }

  .shape-wrap {
    filter: drop-shadow(8px -1px 6px rgba(0, 0, 0, 0.16));

    .shape {
      float: left;
      clip-path: polygon(0 0, 100% 0%, 20% 100%, 0% 100%);
      background-color: #fff;
      height: 64px;
      width: 64px;
    }
  }
`;

const StyledMainNavBar = styled.div`
  width: 100%;
  max-width: 1440px;
  position: fixed;
  z-index: 10;
  background-color: ${props => props.theme.whitePalette.original};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);

  .link-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-right: 22px;

    .link {
      font-weight: 700;
      font-size: 18px;
      /* letter-spacing: 1px; */
      color: ${props => props.theme.primeBluePalette.original};
      margin: 0px 12px;
      cursor: pointer;
    }
  }
  div.ant-ribbon.ant-ribbon-placement-end {
    top: -12px;
    font-size: 10px;
    line-height: 12px;
    height: auto;
    padding: 1px 4px;
    right: 0px;
    font-weight: 600;
    letter-spacing: 1px;
  }
`;

function MainNavigationBarComponent() {
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  const handleOnClick = () => {
    setDrawerVisibility(!drawerVisibility);
  };

  return (
    <StyledMainNavBar>
      <Row type="flex">
        <Col lg={8} sm={18} xs={18}>
          <Link to="/">
            <StyledLogo>
              <img src={logo} alt="PRIME logo" />
              <div className="shape-wrap">
                <div className="shape" />
              </div>
            </StyledLogo>
          </Link>
        </Col>
        <Col lg={16} sm={0} xs={0}>
          <div className="link-wrapper">
            <Popover
              trigger="click"
              placement="bottom"
              getPopupContainer={trigger => trigger.parentElement}
              content={<ExpandedOurExpertiseComponent />}
            >
              <div className="link">
                OUR EXPERTISE <FontAwesomeIcon icon="angle-down" fixedWidth />
              </div>
            </Popover>
            <Popover
              trigger="click"
              placement="bottomRight"
              getPopupContainer={trigger => trigger.parentElement}
              content={<ExpandedIndustryInsightsComponent />}
            >
              <div className="link">
                INDUSTRY INSIGHTS{' '}
                <FontAwesomeIcon icon="angle-down" fixedWidth />
              </div>
            </Popover>
            <Link to="/find-a-property" className="link">
              FIND A PROPERTY
            </Link>
          </div>
        </Col>
        <Col lg={0} sm={6} xs={6}>
          <HamburgerMenuComponent
            drawerVisibility={drawerVisibility}
            handleOnClick={() => handleOnClick()}
          />
        </Col>
      </Row>
      <NavigationDrawerComponent
        drawerVisibility={drawerVisibility}
        handleOnClick={() => handleOnClick()}
      />
    </StyledMainNavBar>
  );
}

export default MainNavigationBarComponent;

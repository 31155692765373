import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import RadarFooterComponent from './footer/RadarFooterComponent';
import MainFooterComponent from './footer/MainFooterComponent';
import SecondaryFooterComponent from './footer/SecondaryFooterComponent';

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterComponent = props => (
  <FooterWrapper>
    <RadarFooterComponent source={props.source} />
    <MainFooterComponent />
    <SecondaryFooterComponent />
  </FooterWrapper>
);

FooterComponent.propTypes = {
  source: PropTypes.string,
};

export default FooterComponent;
